(function($){
  $(document).ready(function() {

    $("#us-map path, #us-map circle").hover(function(e) {
      $("#info-box").css("display", "block");
      $("#info-box").html($(this).data("name"));
    });
    
    $("#us-map path, #us-map circle").mouseleave(function(e) {
      $("#info-box").css("display", "none");
    });

    $(document).mousemove(function(e) {
      $("#info-box").css("top", e.pageY - 55);
      $("#info-box").css("left", e.pageX + 25);
    })
    .mouseover();
	
    var ios = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  	if (ios) {
      $("a").on("click touchend", function() {
  		  var link = $(this).attr("href");
  		  window.open(link, "_blank");
  		  return false;
  	  });
  	}

  });
}(jQuery));